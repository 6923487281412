import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import slick from "slick-carousel";

gsap.registerPlugin(ScrollTrigger);

let sections = gsap.utils.toArray(".side-scrolling-wrapper-panel .panel");

ScrollTrigger.matchMedia({
    "(min-width: 767px)": function () {
        gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none",
            scrollTrigger: {
                trigger: ".side-scrolling-wrapper",
                pin: true,
                // start: "top +=0px",
                scrub: 1,
                // snap: 1 / (sections.length - 1),
                snap: {
                    snapTo: 1 / (sections.length - 1),
                    // duration: { min: 0.01, max: 0.05 },
                    duration: 0.1,
                    delay: 0
                },
                // end: () => "+=" + (document.querySelector(".side-scrolling-wrapper-panel").offsetWidth )
                end: () => "+=" + (document.querySelector(".side-scrolling-wrapper-panel").offsetWidth / 4 )
            }
        });
    }
});

$(document).ready(() => {
    $('.slider').slick({
        dots: true,
        arrows: true,
        adaptiveHeight: true
    });

    var animationDelay = 2500;

    animateHeadline($('.cd-headline'));

    function animateHeadline($headlines) {
        $headlines.each(function () {
            var headline = $(this);
            //trigger animation
            setTimeout(function () { hideWord(headline.find('.is-visible')) }, animationDelay);
            //other checks here ...
        });
    }

    function hideWord($word) {
        var nextWord = takeNext($word);
        switchWord($word, nextWord);
        setTimeout(function () { hideWord(nextWord) }, animationDelay);
    }

    function takeNext($word) {
        return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
    }

    function switchWord($oldWord, $newWord) {
        $oldWord.removeClass('is-visible').addClass('is-hidden');
        $newWord.removeClass('is-hidden').addClass('is-visible');
    }

    $('.burger').click(function () {
        $('.topMenu .wrapper').toggleClass('open');

        if ($(window).width() < 768) {
            $('.topMenu').toggleClass('open');
            $(this).toggleClass('open');
        }
    });

    if ($(window).width() < 767) {
        $('.topMenu .wrapper').append($('.topMenu .languageWrapper'));
    }

    $(".listNavigation a").click(function (e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({ scrollTop: $(aid).offset().top }, 1500);

        if ($(window).width() < 991) {
            $('.topMenu .wrapper').toggleClass('open');
            $('.burger').toggleClass('open');
        }
    });

    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');

});


let $animation_elements = $('.anim');
let $window = $(window);

function check_if_in_view() {
    let window_height = $window.height();
    let window_top_position = $window.scrollTop() - 70;
    let window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
        let $element = $(this);
        let element_height = $element.outerHeight();
        let element_top_position = $element.offset().top;
        let element_bottom_position = (element_top_position + element_height);

        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('in-view');
        }
    });
}